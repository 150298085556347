import { ActivateApiInterface } from '@libs/datalayer';
import {
  EntityFieldMetaInterface,
  EntitySingleConfigInterface,
  EntityTypeEnum,
} from '@libs/core';

export const activateConfig = (activate: ActivateApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Activate,
    title: activate.name,
    data: activate,
    fields: [
      activateFieldNameConfig,
      activateFieldEmailConfig,
      activateFieldPasswordConfig,
    ]
  };
};

export const activateFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  required: true,
};

export const activateFieldEmailConfig: EntityFieldMetaInterface = {
  fieldName: 'email',
  type: 'text',
  required: true,
};

export const activateFieldPasswordConfig: EntityFieldMetaInterface = {
  fieldName: 'password',
  type: 'password',
};
