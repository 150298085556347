// noinspection ES6PreferShortImport

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JsonPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppFieldnamePipe, EntitySingleConfigInterface, FormatFieldPipe } from '@libs/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'lib-entity-details',
  templateUrl: './entity-details.component.html',
  imports: [
    NgForOf,
    JsonPipe,
    NgIf,
    NgSwitchCase,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchDefault,
    TranslateModule,
    AppFieldnamePipe,
    FormatFieldPipe
  ]
})
export class EntityDetailsComponent {

  @Input() public config!: EntitySingleConfigInterface;
  @Input() public canEdit = false;

  @Output() public atEdit: EventEmitter<void> = new EventEmitter<void>();

  public getOption(selectItems: { code: string; name: string }[] | undefined, value: string): string {
    return selectItems?.find((item) => item.code === value)?.name || '';
  }

  onEdit() {
    this.atEdit.emit()
  }
}
