import { CategoryApiInterface } from '@libs/datalayer';
import { EntityFieldMetaInterface, EntitySingleConfigInterface, EntityTypeEnum, MatchTypeEnum } from '@libs/core';

export const categoryConfig = (category: CategoryApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Categories,
    title: category.name,
    data: category,
    fields: [
      categoryFieldNameConfig,
      categoryFieldCountryCodeConfig,
    ]
  };
};

export const categoryFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  required: true,
  hideDisplay: true
};

export const categoryFieldCountryCodeConfig: EntityFieldMetaInterface = {
  fieldName: 'match_type_id',
  type: 'select',
  selectItems: Object.values(MatchTypeEnum).filter((value) => isNaN(Number(value)))
};
