<div
  class="modal"
  [class.modal--inline]="inline"
>
  <div
    class="modal-content"
    [class.modal-content--limit-width]="!fullWidth"
  >
    <ng-content></ng-content>
  </div>
</div>
