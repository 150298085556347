import { inject, Injectable } from '@angular/core';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  ActivateApiInterface,
  CategoryService,
  ContestantApiInterface,
  ContestantService,
  DivisionApiInterface,
  DivisionService,
  InviteApiInterface,
  InviteService,
  TeamApiInterface,
  TeamService,
  TournamentService,
  UserService
} from '@libs/datalayer';
import {
  activateConfig,
  categoryConfig,
  contestantConfig,
  divisionConfig,
  inviteConfig,
  teamConfig,
  tournamentConfig
} from '../../configs';
import { EntityFormInterface, EntityInterface, EntityTypeEnum, UserTypeEnum } from '@libs/core';
import { ActivateService } from '@libs/datalayer';

@Injectable({ providedIn: 'root' })
export class EntityService {

  private entitySubject: Subject<EntityFormInterface | null> = new Subject<EntityFormInterface | null>();
  public entity$ = this.entitySubject.asObservable();

  private tournamentService = inject(TournamentService);
  private teamService = inject(TeamService);
  private contestantService = inject(ContestantService);
  private categoryService = inject(CategoryService);
  private divisionService = inject(DivisionService);
  private inviteService = inject(InviteService);
  private userService = inject(UserService);
  private activateService = inject(ActivateService);

  tournamentEdit(id: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();
    return this.tournamentService.get(id)
      .pipe(
        tap((tournament) => {
          if (tournament) {
            this.entitySubject.next({
              entity: tournament,
              service: this.tournamentService,
              config: tournamentConfig(tournament),
              callback: returnSubject
            });
          }
        }),
        switchMap(() => returnSubject.asObservable())
      );
  }

  teamEdit(id: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();
    return this.teamService.get(id).pipe(
      tap((team) => {
        if (team) {
          this.entitySubject.next({
            entity: team,
            service: this.teamService,
            config: teamConfig(team),
            callback: returnSubject
          });
        }
      }),
      switchMap(() => returnSubject.asObservable())
    );
  }

  teamAdd(tournamentId: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const newTeam: TeamApiInterface = {
      type: EntityTypeEnum.Tournaments,
      tournament_id: tournamentId,
      id: '',
      name: '',
      order: 0
    };
    this.entitySubject.next({
      entity: newTeam,
      service: this.teamService,
      config: teamConfig(newTeam),
      callback: returnSubject
    });
    return returnSubject.asObservable();
  }

  categoryEdit(id: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();
    return this.categoryService.get(id).pipe(
      tap((category) => {
        if (category) {
          this.entitySubject.next({
            entity: category,
            service: this.categoryService,
            config: categoryConfig(category),
            callback: returnSubject
          });
        }
      }),
      switchMap(() => returnSubject.asObservable())
    );
  }

  contestantEdit(id: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();
    return this.contestantService.get(id).pipe(
      tap((contestant) => {
        if (contestant) {
          this.entitySubject.next({
            entity: contestant,
            service: this.contestantService,
            config: contestantConfig(contestant),
            callback: returnSubject
          });
        }
      }),
      switchMap(() => returnSubject.asObservable())
    );
  }

  divisionEdit(id: string) {
    const returnSubject = new Subject<EntityInterface | null | undefined>();
    return this.divisionService.get(id).pipe(
      tap((division) => {
        if (division) {
          this.entitySubject.next({
            entity: division,
            service: this.divisionService,
            config: divisionConfig(division),
            callback: returnSubject
          });
        }
      }),
      switchMap(() => returnSubject.asObservable())
    );
  }

  divisionAdd(tournamentId: string, categoryId: string): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const newDivision: DivisionApiInterface = {
      type: EntityTypeEnum.Divisions,
      tournament_id: tournamentId,
      category_id: categoryId,
      id: '',
      name: '',
      display_name: '',
      order: 0,
      min_age: null,
      max_age: null,
      min_weight: null,
      max_weight: null,
      gender: null,
      contestants: [],
      matches: []
    };
    this.entitySubject.next({
      entity: newDivision,
      service: this.divisionService,
      config: divisionConfig(newDivision),
      callback: returnSubject
    });
    return returnSubject.asObservable();
  }

  divisionCopy(tournamentId: string, categoryId: string, divisionToCopy: DivisionApiInterface): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const newDivision: DivisionApiInterface = {
      type: EntityTypeEnum.Divisions,
      tournament_id: tournamentId,
      category_id: categoryId,
      id: '',
      name: divisionToCopy.name,
      display_name: divisionToCopy.display_name,
      order: 0,
      min_age: divisionToCopy.min_age,
      max_age: divisionToCopy.max_age,
      min_weight: divisionToCopy.min_weight,
      max_weight: divisionToCopy.max_weight,
      gender: divisionToCopy.gender,
      contestants: [],
      matches: []
    };
    this.entitySubject.next({
      entity: newDivision,
      service: this.divisionService,
      config: divisionConfig(newDivision),
      callback: returnSubject
    });
    return returnSubject.asObservable();
  }

  inviteUser(tournamentId: string, userType: UserTypeEnum): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const newInvite: InviteApiInterface = {
      type: EntityTypeEnum.Invite,
      tournament_id: tournamentId,
      id: '',
      name: '',
      order: 0,
      email: '',
      user_type: userType
    };
    this.entitySubject.next({
      entity: newInvite,
      service: this.inviteService,
      config: inviteConfig(newInvite),
      callback: returnSubject
    });
    return returnSubject.asObservable();
  }

  contestantAdd(team: TeamApiInterface): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const newContestant: ContestantApiInterface = {
      type: EntityTypeEnum.Contestants,
      tournament_id: team.tournament_id,
      team_id: team.id,
      id: '',
      name: '',
      order: 0,
      min_age: null,
      max_age: null,
      min_weight: null,
      max_weight: null,
      gender: null,
      contestants: [],
      matches: []
    };
    this.entitySubject.next({
      entity: newContestant,
      service: this.contestantService,
      config: contestantConfig(newContestant),
      callback: returnSubject
    });
    return returnSubject.asObservable();
  }

  activate(invite: InviteApiInterface): Observable<EntityInterface | null | undefined> {
    const returnSubject = new Subject<EntityInterface | null | undefined>();

    const activate: ActivateApiInterface = {
      id: '',
      tournament_id: '',
      order: 0,
      type: EntityTypeEnum.Activate,
      invite_id: invite.id,
      name: invite.name,
      email: invite.email,
      password: '',
    };
    this.entitySubject.next({
      entity: activate,
      service: this.activateService,
      config: activateConfig(activate),
      callback: returnSubject,
      inline: true,
    });
    return returnSubject.asObservable();
  }

}
