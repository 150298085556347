import { Component, Input } from '@angular/core';
import { ModalService } from '../../core';

@Component({
  standalone: true,
  selector: 'lib-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() fullWidth = false;
  @Input() inline = false;

  constructor(
    private modalService: ModalService
  ) {
  }

  closeModal() {
    this.modalService.closeModal();
  }

}
