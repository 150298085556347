import { DivisionApiInterface } from '@libs/datalayer';
import { EntityFieldMetaInterface, EntitySingleConfigInterface, EntityTypeEnum } from '@libs/core';

export const divisionConfig = (division: DivisionApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Divisions,
    title: division.name,
    data: division,
    fields: divisionFields
  };
};


export const divisionFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
};
export const divisionFieldDisplayNameConfig: EntityFieldMetaInterface = {
  fieldName: 'display_name',
  type: 'text',
};

export const divisionFieldMinAgeConfig: EntityFieldMetaInterface = {
  fieldName: 'min_age',
  type: 'number',
  required: false,
};
export const divisionFieldMaxAgeConfig: EntityFieldMetaInterface = {
  fieldName: 'max_age',
  type: 'number',
  required: false,
};
export const divisionFieldMinWeightConfig: EntityFieldMetaInterface = {
  fieldName: 'min_weight',
  type: 'number',
  required: false,
};
export const divisionFieldMaxWeightConfig: EntityFieldMetaInterface = {
  fieldName: 'max_weight',
  type: 'number',
  required: false,
};
export const divisionFieldGenderConfig: EntityFieldMetaInterface = {
  fieldName: 'gender',
  type: 'male-female',
  required: false,
};

export const divisionFields: EntityFieldMetaInterface[] = [
  divisionFieldNameConfig,
  divisionFieldGenderConfig,
  divisionFieldMinAgeConfig,
  divisionFieldMaxAgeConfig,
  divisionFieldMinWeightConfig,
  divisionFieldMaxWeightConfig
]
