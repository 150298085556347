import { InviteApiInterface } from '@libs/datalayer';
import {
  EntityFieldMetaInterface,
  EntitySingleConfigInterface,
  EntityTypeEnum,
  UserTypeEnum
} from '@libs/core';

export const inviteConfig = (invite: InviteApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Invite,
    title: invite.name,
    data: invite,
    fields: [
      inviteFieldNameConfig,
      inviteFieldEmailConfig,
      inviteFieldTypeCodeConfig,
    ]
  };
};

export const inviteFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  required: true,
};

export const inviteFieldEmailConfig: EntityFieldMetaInterface = {
  fieldName: 'email',
  type: 'text',
  required: true,
};

export const inviteFieldTypeCodeConfig: EntityFieldMetaInterface = {
  fieldName: 'user_type',
  type: 'select',
  selectItems:  [UserTypeEnum.TOURNAMENT_ADMIN]
};
