<lib-modal
  *ngIf="entityResolveData && entityResolveData?.entity as entity"
  [inline]="entityResolveData.inline || false">
  <h4>{{ entity.name }}</h4>
  <ng-container *ngFor="let field of entityResolveData.config.fields || []">
    <ng-container *ngIf="!field.hideEdit">
      <div class="row">
        <div class="twelve columns">
          <div class="app-flex">
            <label>{{ field | appFieldname: entityResolveData.config.entityType | translate }}</label>
            <i>{{ field.required ? '*' + ('term.required' | translate) : '' }}</i>
          </div>
          <ng-container [ngSwitch]="field.type">
            <input
              *ngSwitchCase="'text'"
              class="u-full-width"
              [readonly]="field.readOnly"
              type="text"
              [(ngModel)]="entity[field.fieldName]"
            >
            <input
              *ngSwitchCase="'number'"
              class="u-full-width"
              [readonly]="field.readOnly"
              type="number"
              [(ngModel)]="entity[field.fieldName]"
            >
            <input
              *ngSwitchCase="'password'"
              class="u-full-width"
              type="password"
              [(ngModel)]="entity[field.fieldName]"
            >
            <input
              *ngSwitchCase="'date'"
              class="u-full-width"
              type="date"
              [(ngModel)]="entity[field.fieldName]"
            >
            <ng-container *ngSwitchCase="'open-closed'">
              <ul class="app-radio-group">
                <li>
                  <input id="open-closed-o" [(ngModel)]="entity[field.fieldName]" type="radio" name="open-closed" [value]="1"/>
                  <label for="open-closed-o">{{ 'term.open' | translate }}</label>
                </li>
                <li>
                  <input id="open-closed-c" [(ngModel)]="entity[field.fieldName]" type="radio" name="open-closed" [value]="0" />
                  <label for="open-closed-c">{{ 'term.closed' | translate }}</label>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngSwitchCase="'yes-no'">
              <ul class="app-radio-group">
                <li>
                  <input id="yes-no-y" [(ngModel)]="entity[field.fieldName]" type="radio" name="yes-no" [value]="true"/>
                  <label for="yes-no-y">{{ 'term.yes' | translate }}</label>
                </li>
                <li>
                  <input id="yes-no-n" [(ngModel)]="entity[field.fieldName]" type="radio" name="yes-no" [value]="false" />
                  <label for="yes-no-n">{{ 'term.no' | translate }}</label>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngSwitchCase="'male-female'">
              <ul class="app-radio-group">
                <li>
                  <input id="gender-m" [(ngModel)]="entity[field.fieldName]" type="radio" name="gender" value="M"/>
                  <label for="gender-m">{{ 'term.male' | translate }}</label>
                </li>
                <li>
                  <input id="gender-f" [(ngModel)]="entity[field.fieldName]" type="radio" name="gender" value="F" />
                  <label for="gender-f">{{ 'term.female' | translate }}</label>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngSwitchCase="'select'">
              <select [(ngModel)]="entity[field.fieldName]" class="u-full-width">
                <option
                  *ngFor="let option of field.selectItems || []"
                  [value]="option"
                >{{ 'options.' + field.fieldName + '.' + option | translate }}
                </option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'flag'">
              <select [(ngModel)]="entity[field.fieldName]" class="u-full-width">
                <option
                  *ngFor="let option of field.selectItems || []"
                  [value]="option"
                >{{ 'options.' + field.fieldName + '.' + option | translate }}
                </option>
              </select>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="app-flex app-flex-wrap-reverse">
    <button *ngIf="entity.id" class="button-warn button-icon" (click)="onDelete()">
      <div class="app-icon app-icon-delete"></div>
    </button>
    <div class="app-flex-spacer"></div>
    <button (click)="onCancel()">
      {{ 'button.cancel' | translate }}
    </button>
    <button class="button-primary" (click)="onSave()">
      {{ 'button.save' | translate }}
    </button>
  </div>

</lib-modal>
