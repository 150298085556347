import { TeamApiInterface } from '@libs/datalayer';
import { countries, EntityFieldMetaInterface, EntitySingleConfigInterface, EntityTypeEnum } from '@libs/core';

export const teamConfig = (team: TeamApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Teams,
    title: team.name,
    data: team,
    fields: [
      teamFieldNameConfig,
      teamFieldCountryCodeConfig,
    ]
  };
};

export const teamFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  required: true,
  hideDisplay: true
};

export const teamFieldCountryCodeConfig: EntityFieldMetaInterface = {
  fieldName: 'country_code',
  type: 'flag',
  selectItems: countries
};
