<div *ngIf="config" class="container u-full-width app-card">
  <div class="row">
    <span class="h5">{{ config.title | translate }}</span>
    <button
      class="u-pull-right"
      *ngIf="canEdit"
      (click)="onEdit()"
    >{{ 'button.edit' | translate }}
    </button>
  </div>
  <div class="row">
    <table>
      <ng-container *ngFor="let field of config.fields">
        <tr *ngIf="!field.hideDisplay">
          <td><i>{{ field | appFieldname: config.entityType | translate }}</i></td>
          <td *ngIf="config.data[field.fieldName] as fieldValue">
            {{ fieldValue | formatField:field | translate}}
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
