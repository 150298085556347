import { UserApiInterface } from '@libs/datalayer';
import {
  EntityFieldMetaInterface,
  EntitySingleConfigInterface,
  EntityTypeEnum,
  UserTypeEnum
} from '@libs/core';

export const userConfig = (user: UserApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Users,
    title: user.name,
    data: user,
    fields: [
      userFieldNameConfig,
      userFieldEmailConfig,
      userFieldPasswordConfig,
    ]
  };
};

export const userFieldNameConfig: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  required: true,
};

export const userFieldEmailConfig: EntityFieldMetaInterface = {
  fieldName: 'email',
  type: 'text',
  required: true,
};

export const userFieldPasswordConfig: EntityFieldMetaInterface = {
  fieldName: 'password',
  type: 'password',
};

export const userFieldTypeCodeConfig: EntityFieldMetaInterface = {
  fieldName: 'user_type',
  type: 'select',
  selectItems:  [UserTypeEnum.TOURNAMENT_ADMIN]
};
