import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@libs/components/modal';
import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { EntityService } from '../core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppFieldnamePipe, EntityFormInterface, EntityInterface } from '@libs/core';

@Component({
  standalone: true,
  selector: 'lib-entity-edit',
  templateUrl: './entity-edit.component.html',
  imports: [
    ModalComponent,
    NgIf,
    FormsModule,
    NgForOf,
    NgSwitchCase,
    TranslateModule,
    NgSwitch,
    AppFieldnamePipe
  ]
})
export class EntityEditComponent implements OnInit {

  entityResolveData: EntityFormInterface | null = null;
  dirty = false;

  constructor(
    private entityService: EntityService
  ) {
  }

  ngOnInit() {
    this.entityService.entity$.subscribe( (data) => this.entityResolveData = data);
  }

  closeModal(value: EntityInterface | null | undefined) {
    this.entityResolveData?.callback.next(value);
    this.entityResolveData?.callback.complete();
    this.entityResolveData = null;
  }

  onDelete() {
    if (this.entityResolveData?.service && this.entityResolveData?.entity && confirm(`You're about to delete ${this.entityResolveData?.entity.name}, Are you sure?`)) {
      this.entityResolveData?.service.deleteItem(this.entityResolveData?.entity.id).subscribe((success: boolean): void => {
        if (success) {
          this.closeModal(null);
          this.entityResolveData?.callback.next(null);
          this.entityResolveData?.callback.complete();
        }
      });
    }
  }

  onCancel() {
    if (this.dirty) {
      if (confirm(`You will lose unsaved changes, Are you sure?`)) {
        this.closeModal(undefined);
        this.entityResolveData?.callback.next(undefined);
        this.entityResolveData?.callback.complete();
      }
    } else {
      this.closeModal(undefined);
      this.entityResolveData?.callback.next(undefined);
      this.entityResolveData?.callback.complete();
    }
  }

  onSave() {
    if (this.entityResolveData?.service && this.entityResolveData?.entity) {
      this.entityResolveData?.service.save(this.entityResolveData?.entity).subscribe((result: EntityInterface|null): void => {
        if (result) {
          this.closeModal(result);
          this.entityResolveData?.callback.next(result);
          this.entityResolveData?.callback.complete();
        }
      });
    }
  }
}
