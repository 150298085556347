import { TournamentApiInterface } from '@libs/datalayer';
import { EntitySingleConfigInterface, EntityTypeEnum } from '@libs/core';

export const tournamentConfig = (tournament: TournamentApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Tournaments,
    title: tournament.name,
    data: tournament,
    fields: [
      {
        fieldName: 'name',
        type: 'text',
        required: true,
        hideDisplay: true,
      },
      {
        fieldName: 'date',
        type: 'date'
      }
    ]
  };
};
