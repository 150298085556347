import { EntityFieldMetaInterface, EntitySingleConfigInterface, EntityTypeEnum } from '@libs/core';
import { ContestantApiInterface } from '@libs/datalayer';

export const contestantConfig = (contestant: ContestantApiInterface): EntitySingleConfigInterface => {
  return {
    entityType: EntityTypeEnum.Contestants,
    title: contestant.name,
    data: contestant,
    fields: contestantFields
  };
};

export const contestantFields: EntityFieldMetaInterface[] = [
  { fieldName: 'first_name', type: 'text', required: true },
  { fieldName: 'last_name_prefix', type: 'text' },
  { fieldName: 'last_name', type: 'text', required: true },
  { fieldName: 'weight_self', type: 'number' },
  { fieldName: 'weight_official', type: 'number' },
  { fieldName: 'date_of_birth', type: 'date' },
  { fieldName: 'gender', type: 'male-female', required: true },
  { fieldName: 'email', type: 'text' }
];

export const contestantNameField: EntityFieldMetaInterface = {
  fieldName: 'name',
  type: 'text',
  readOnly: true,
}

export const contestantGenderField: EntityFieldMetaInterface = {
  fieldName: 'gender',
  type: 'male-female',
  readOnly: true,
}

export const contestantAgeField: EntityFieldMetaInterface = {
  fieldName: 'age_at_tournament',
  type: 'number',
  readOnly: true,
}

export const contestantWeightField: EntityFieldMetaInterface = {
  fieldName: 'weight_official',
  type: 'number',
  readOnly: true,
}

